$custom-bp: (
  small: 575.98px,   // equivalent to bootstrap sm
  medium: 767.98px,  // equivalent to bootstrap md
  large: 1919.98px     // for 4k and above
);

$bp-small: map-get($custom-bp, "small");
$bp-medium: map-get($custom-bp, "medium");
$bp-large: map-get($custom-bp, "large");

// @param:
//      $property: {type: string}
//      $floor: {type: number; unit: must same with ceil}
//      $ceil: {type: number; unit: must same with floor}
// @return: vw and rem
// Use Case: fluid("font-size", 1.2rem, 1.4rem)

@mixin fluid($property, $floor, $ceiling: $floor) {
  // @if type-of($property) != "string" {
  //   @error "Sorry, you must insert string for $property on fluid mixin";
  // }

  // @if unit($floor) != unit($ceiling) {
  //   @error "Sorry, the $floor and $ceiling unit must match on fluid mixin";
  // }

  & {
    #{$property}: $floor;

    @media screen and (min-width: $bp-small) {
      #{$property}: calc(
        #{$floor} + #{stripUnit($ceiling - $floor)} *
          (
            (100vw - #{$bp-small}) /
              (#{stripUnit($bp-medium) - stripUnit($bp-small)})
          )
      );
    }

    @media screen and (min-width: $bp-medium) {
      #{$property}: $ceiling;
    }

    @media screen and (min-width: $bp-large) {
      #{$property}: #{stripUnit($ceiling) / stripUnit($bp-large) * 100vw};
    }
  }
}

// Padding Mixins
@mixin fluidpaddingx($floor, $ceiling: $floor) {
  @include fluid("padding-left", $floor, $ceiling);
  @include fluid("padding-right", $floor, $ceiling);
}

@mixin fluidpaddingy($floor, $ceiling: $floor) {
  @include fluid("padding-top", $floor, $ceiling);
  @include fluid("padding-bottom", $floor, $ceiling);
}

@mixin fluidpadding($floor, $ceiling: $floor) {
  @include fluidpaddingx($floor, $ceiling);
  @include fluidpaddingy($floor, $ceiling);
}

// Margin Mixins
@mixin fluidmarginx($floor, $ceiling: $floor) {
  @include fluid("margin-left", $floor, $ceiling);
  @include fluid("margin-right", $floor, $ceiling);
}

@mixin fluidmarginy($floor, $ceiling: $floor) {
  @include fluid("margin-top", $floor, $ceiling);
  @include fluid("margin-bottom", $floor, $ceiling);
}

@mixin fluidmargin($floor, $ceiling: $floor) {
  @include fluidmarginx($floor, $ceiling);
  @include fluidmarginy($floor, $ceiling);
}