@import "./common";

.homepage-hero {
  width: 100%;
  min-height: 50vh;
  background: url("../../../assets/page_homepage/homepageHero.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @media screen and (max-width: 768px) {
    min-height: 100vh;
  }
  
  .hero-text {
    text-align: center;
    color: map-get($theme-colors, "white");
    
    h1 {
      @include fluid("font-size", 24px, 40px);
      font-weight: bold;
    }

    h2 {
      @include fluid("font-size", 16px, 30px);
    }
  }

  .hero-search {
    width: 100%;
    @include fluid("max-width", 576px, 768px);
  }
  
}