.sre-footer {
  background-color: var(--primary);
  color: var(--white);

  .footer-social {
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer-social-badges {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      @media screen and (max-width: 768px) {
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
      }

      .footer-badge {
        width: 100%;
        max-width: 160px;
      }
    }
  
    .footer-social-links {
      display: flex;
      justify-content: center;
  
      & > * {
        margin-left: .5rem;
        margin-right: .5rem;
        cursor: pointer;
      }
    }
  }
}