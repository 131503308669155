/* All */
.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn {
  padding: 0.3rem 0;
  min-width: max-content;
}

.hoverable {
  color: var(--white);
}

/* Nav & Sidebar */
nav {
  background-color: var(--primary);
  color: var(--white);
  display: flex;
  flex-direction: column;
  // padding: 1rem 1rem;
  z-index: 3;
  width: 100vw;
  max-width: 100vw !important;
  top: 0;
  position: fixed;
  height: 70px !important;
}

.navlist-mobile {
  background-color: var(--primary);
  width: 100%;
}

.navlist-mobile > * {
  margin-left: 24px;
  &:last-child {
    margin-bottom: 18px;
  }
}

aside {
  position: fixed;
  width: 30vw;
  // max-width: 135px;
  max-width: 100px;
  height: 100vh;
  background-color: var(--primary);
  border-right: 1px solid black;
  z-index: 2;
  transform: translate3d(-100%, 0, 0);
  transition: all 0.4s ease-out;
  display: none;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.show {
  transform: translate3d(0, 0, 0);
  transition: all 0.4s ease-out;
}

.sidebar-content {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  // font-size: 12px;
  margin-top: 70px;
}

.main-menu > svg {
  width: 100%;
  min-height: 20px;
  // font-size: 28px;
}

.additional-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--white);
  width: 100%;
  min-height: 53px;
}

.additional-menu-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.additional-menu-icon > .btn {
  color: var(--white);
}

@media only screen and (max-width: 768px) {
  // .additional-menu-icon {
  //   font-size: 28px;
  // }

  .additional-menu-icon > .btn {
    width: 100%;
    min-height: 53px;
    text-align: center;
    // font-size: 25px;
    // padding-left: 35px;
  }
}

.mobile-additional-menu {
  position: absolute;
  width: 100%;
  bottom: 20px;
}

/* Dropdown */
.menu {
  background: var(--primary);
  position: absolute;
  max-width: max-content;
  z-index: 4;
  right: 1rem;
}
.lang-dropdown {
  color: var(--white) !important;
  max-width: 50px !important;
}

@media only screen and (max-width: 768px) {
  .menu > .dropdown-item {
    z-index: 4;
    padding: 0.25rem 0.1rem;
    font-size: 15px;
  }
  .lang-dropdown {
    width: 100% !important;
    max-width: 100% !important;
  }
}

/* Search bar  */
.search-bar {
  width: 80%;
  height: 100%;
  max-height: 28px;
}
#search-btn {
  width: 20%;
  max-width: 30px;
  height: 30px;
  padding: 0 0;
  background-color: var(--tooltip) !important;
}

/* Hamburger */
#hamburger {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}

#hamburger .closed {
  transform: rotate(180deg);
}

.line {
  height: 3px;
  width: 20px;
  background: var(--white);
  margin: 2px 0px;
  transition: all 0.2s ease-in-out;
}

.closed .line:nth-child(1) {
  transform: rotate(45deg) translate(6px, 6px);
  border-radius: 30px;
}

.closed .line:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}

.closed .line:nth-child(3) {
  transform: rotate(-45deg) translate(4px, -4px);
  border-radius: 30px;
}

.pop-out {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 0.5s ease-in;
}
