@import "../../../styles/bundle";

.homepage-whywithus {
  width: 100%;
  min-height: 50vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  .whywithus-title {
    h3 {
      @include fluid("font-size", 22px, 24px);
      font-weight: bold;
      margin-bottom: 0;
    }

    // p {
    //   @include fluid("font-size", 12px, 14px);
    // }
  }

  .whywithus-cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;


    .whywithus-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      @include fluid("max-width", 160px, 200px);
      @include fluid("margin", 20px);

      h4 {
        @include fluid("font-size", 14px, 18px);
        @include fluid("margin-bottom", 10px);
        // font-weight: bold;
      }
  
      // p {
      //   @include fluid("font-size", 12px, 14px);
      // }

      img {
        @include fluid("width", 120px, 160px);
        @include fluid("margin-bottom", 10px);
      }
    }
  }
}